import merge from 'lodash/merge'

const commonConfig = {
  auth: {
    authorizationPath: '/auth/oauth/v2/authorize',
    logoutPath: '/login/responses/logoff.html',
    popupOptions: { width: 482, height: 680 },
    redirectUri: `${window.location.origin}`,
    domainName: `${window.location.origin}`,
    responseType: 'token id_token',
    scope: ['openid profile'],
    storageType: 'localStorage',
    tokenType: 'Bearer',
  },
  mailToHref: 'mailto:?subject=RDMPLUS%20file%20link%20for%20',
}

const envConfigs = {
  dev: {
    auth: {
      host: 'https://oauth.iam.perf.target.com',
      logoutHost: 'https://logonservices.iam.perf.target.com',
      authorizationUrl:
        'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
      clientId: 'rdmplus_npe_im',
      nonce: '1234',
      env: 'dev',
      logoutUrl:
        'https://logonservices.iam.perf.target.com/login/responses/logoff.html',
    },
    apiUrl: {
      fetchFilSize:
        'https://rdmplus.perf.target.com/real_estate_documents/v1/document/filesize?key=7db25859ffd922010569336cf9b5e34ddee7b89f',
      multipleDownloadUrl:
        'https://rdmplus.perf.target.com/real_estate_documents/v1/document/downloads/zip?key=7db25859ffd922010569336cf9b5e34ddee7b89f',
      getFileDownloadUrl: 'https://rdm.dev.target.com/filename?fileName=',
      sendMailUrl: `https://rdmcoreapi.dev.target.com/mail/sendMail?key=7db25859ffd922010569336cf9b5e34ddee7b89f`,
      fileUrl: 'https://rdmapi.dev.target.com/filename',
      fileUrlExternal: 'https://rdmplus.perf.target.com/filename',
      // searchURL: 'https://rdmcoreapi.dev.target.com/search/metadata/',
      searchURL:
        'https://rdmplus.perf.target.com/real_estate_documents/v1/document/search/metadata/',
      search_elastic:
        'https://rdmplus.perf.target.com/real_estate_documents/v1/document/search/es/',
      // search_elastic: 'https://rdmcoreapi.dev.target.com/search/es/',
      searchKey: '?key=7db25859ffd922010569336cf9b5e34ddee7b89f',
      indexUrl: 'https://rdmcoreapi.dev.target.com/',
      noBatchUploadUrl:
        'https://rdmcoreapi.dev.target.com/insert/index/metadata',
      updateMetaUrl: 'https://rdmcoreapi.dev.target.com/update/metadata/',
      batchUrl: `https://rdmcoreapi.dev.target.com/update/batch/scan/`,
      getFileUrl: 'https://rdmapi.dev.target.com/filename?fileName=',
      getFileUrlExternal:
        'https://rdmplus.perf.target.com/real_estate_documents/v1/view/file?fileName=',
      get_file_letter: 'https://rdmlocation.dev.target.com/get/fileletter/',
      release_batch_url: `https://rdmcoreapi.dev.target.com/release/batch/`,
      key_url: `?key=7db25859ffd922010569336cf9b5e34ddee7b89f`,
      keyUrl: `&key=7db25859ffd922010569336cf9b5e34ddee7b89f`,
      get_batchList_url: `https://rdmcoreapi.dev.target.com/scan/files/`,
      // get_batch_metadata: `https://rdmcoreapi.dev.target.com/get/metadata?id=`,
      get_batch_metadata: `https://rdmplus.perf.target.com/real_estate_documents/v1/document/metadata?id=`,
      update_checkout_metadata: `https://rdmcoreapi.dev.target.com/update/checkoutmetadata`,
      update_checkin_metadata: `https://rdmcoreapi.dev.target.com/upload/check`,
      cancel_checkout: 'https://rdmcoreapi.dev.target.com/cancel/checkout',
      ad_group_url: `https://stgapi-internal.target.com/ldap_directory_items/v1/group_members?group=APP-rdmp-publicdocuments-stage&key=7db25859ffd922010569336cf9b5e34ddee7b89f`,
      adGroupFirtsPart:
        'https://stgapi-internal.target.com/ldap_directory_items/v1/group_members?group=',
      adGroupSecondPart: '&key=7db25859ffd922010569336cf9b5e34ddee7b89f',
      legal_adgroup_url: `https://stgapi-internal.target.com/ldap_directory_items/v1/group_members?group=app-rdmp-legalfollowup-re-stage&key=7db25859ffd922010569336cf9b5e34ddee7b89f`,
      business_adgroup_url: `https://stgapi-internal.target.com/ldap_directory_items/v1/group_members?group=app-rdmp-bpfollowup-re-stage&key=7db25859ffd922010569336cf9b5e34ddee7b89f`,
      location_adgroup_url: `https://stgapi-internal.target.com/ldap_directory_items/v1/group_members?group=app-rdmp-loc-re-stage&key=7db25859ffd922010569336cf9b5e34ddee7b89f`,
      change_batch_dialog_content: `Indexing is in progress, if you change the batch then all the tabs will close! Click Change batch to close tabs and go to new batch and Cancel to go back to indexing!`,
      change_batch_first_button: `Change batch`,
      change_batch_second_button: `Cancel`,
      delete_workflow_dialog_content:
        'Are you sure you want to delete this workflow?',
      delete_workflow_first_button: 'Delete',
      delete_workflow_second_button: 'Cancel',
      delete_url: `https://rdmcoreapi.dev.target.com/delete/scan/files?key=7db25859ffd922010569336cf9b5e34ddee7b89f`,
      change_user_first_button: `Change User`,
      change_user_second_button: `Cancel`,
      upload_files_url: `https://rdmcoreapi.dev.target.com/upload?key=7db25859ffd922010569336cf9b5e34ddee7b89f&path=`,
      fetch_complete_batch_metadata: `https://rdmcoreapi.dev.target.com/scan/file/metadata/`,
      locationApi: `https://rdmlocation.dev.target.com/get/locationdetails`,
      location_table: 'https://rdmlocation.dev.target.com/get/listalllocation',
      owned_location:
        'https://rdmlocation.dev.target.com/get/listchecklistbytype/Owned',
      leased_location:
        'https://rdmlocation.dev.target.com/get/listchecklistbytype/Leased',
      add_location: 'https://rdmlocation.dev.target.com/insert/location/',
      update_location: 'https://rdmlocation.dev.target.com/update/location',
      update_dispositiondate:
        'https://rdmlocation.dev.target.com/update/dispositiondate',
      getlocation: 'https://rdmlocation.dev.target.com/get/listalllocation/',
      getownedloction:
        'https://rdmlocation.dev.target.com/getchecklistbyid/Owned/',
      update_ownedlocation:
        'https://rdmlocation.dev.target.com/update/checklist/owned',
      getleasedloction:
        'https://rdmlocation.dev.target.com/getchecklistbyid/Leased/',
      update_leasedlocation:
        'https://rdmlocation.dev.target.com/update/checklist/leased',
      getfileletter: 'https://rdmlocation.dev.target.com/get/fileletter/',
      getfileletterbyid:
        'https://rdmlocation.dev.target.com/get/fileletterbyid/',
      addfileletter: 'https://rdmlocation.dev.target.com/insert/fileletter',
      updatefileletter: 'https://rdmlocation.dev.target.com/update/fileletter',
      getCheckoutHistory:
        'https://rdmlocation.dev.target.com/get/checkouthistory/byid/',
      checkInOutFileLetter:
        'https://rdmlocation.dev.target.com/checkout/fileletter',
      get_supporting_documents_list:
        'https://rdmplus.perf.target.com/real_estate_documents/v1/document/supportingdocuments',
      delete_supporting_documents_list:
        'https://rdmcoreapi.dev.target.com/delete/supportingdocuments?docId=',
      upload_supporting_documents_list:
        'https://rdmcoreapi.dev.target.com/upload/supportingdocuments?docId=',
      get_workflows: 'https://rdmworkflow.dev.target.com/get/workflows',
      get_workflow: 'https://rdmworkflow.dev.target.com/get/workflow',
      save_workflow: 'https://rdmworkflow.dev.target.com/save/workflow',
      submit_workflow: 'https://rdmworkflow.dev.target.com/workflow/response',
      delete_workflow: 'https://rdmworkflow.dev.target.com/delete/workflow',
      workflownotes: 'https://rdmworkflow.dev.target.com/get/workflownotes',
      addworkflownotes:
        'https://rdmworkflow.dev.target.com/insert/workflownotes',
      getworkflowhistory:
        'https://rdmworkflow.dev.target.com/get/workflowhistory',
      workflow_retrigger: 'https://rdmworkflow.dev.target.com/workflow/start',
      delete_file:
        'https://rdmcoreapi.dev.target.com/delete/documents?key=7db25859ffd922010569336cf9b5e34ddee7b89f',
      // auth_url: 'https://rdmapi.dev.target.com/auth/?adgroups=',
      auth_url:
        'https://rdmplus.perf.target.com/real_estate_documents/v1/view/auth/?adgroups=',
      getcamnoticedocs:
        'https://rdmreporting.dev.target.com/get/camnoticedocsbydate?',
      geteasementdocs:
        'https://rdmreporting.dev.target.com/get/leaseeasementdocsbydate?',
      getnoticedocs:
        'https://rdmreporting.dev.target.com/get/leasenoticedocsbydate?',
      getpendingdocs:
        'https://rdmreporting.dev.target.com/get/leasependingdocsbydate?',
      getreviewdocs:
        'https://rdmreporting.dev.target.com/get/leasereviewdocsbydate?',
      getleasequarterlydocs:
        'https://rdmreporting.dev.target.com/get/leasequarterlyinbox?',
      getcamquarterlydocs:
        'https://rdmreporting.dev.target.com/get/camquarterlyinbox?',
      getcampayablebudgetdocs:
        'https://rdmreporting.dev.target.com/get/campayablebudget?',
      getpayablebucketrecords:
        'https://rdmreporting.dev.target.com/get/payablebucketwiserecords?',
      getrebillbucketrecords:
        'https://rdmreporting.dev.target.com/get/rebillbucketwiserecords?',
      getpayablecompletedrecords:
        'https://rdmreporting.dev.target.com/get/completedcampayableworkflow?',
      getrebillcompletedrecords:
        'https://rdmreporting.dev.target.com/get/completedcamrebillworkflow?',
      getreturnedpayableunexpectedrecords:
        'https://rdmreporting.dev.target.com/get/returnedpayableunexpectedrecords?',
      getreturnedrebillrecords:
        'https://rdmreporting.dev.target.com/get/returnedrebillrecords?',
      getreturnedpayableandrecrecords:
        'https://rdmreporting.dev.target.com/get/returnedpayableandrecrecords?',
      bulkEditSearch:
        'https://rdmcoreapi.dev.target.com/bulkedit/search?key=7db25859ffd922010569336cf9b5e34ddee7b89f',
      bulkEditUpdate:
        'https://rdmcoreapi.dev.target.com/bulkedit/update?key=7db25859ffd922010569336cf9b5e34ddee7b89f',
      rdmapiHealth: 'https://rdmapi.dev.target.com/rdmapi/v1/health',
      datascienceHealth:
        'https://datascienceintg.dev.target.com/datascience/v1/health',
      rdmlocationHealth:
        'https://rdmlocation.dev.target.com/rdmlocation/v1/health',
      rdmocrHealth: 'https://rdmocr.dev.target.com/rdmocr/v1/health',
      ldmtordmsyncHealth:
        'https://ldmtordmsync.dev.target.com/locationsync/v1/health',
      rdmcoreapiHealth:
        'https://rdmcoreapi.dev.target.com/rdmcoreapi/v1/health',
      storageHealth: 'https://rdmcoreapi.dev.target.com/actuator/health',
      rdmworkflowHealth:
        'https://rdmworkflow.dev.target.com/rdmworkflow/v1/health',
      rdmreportingHealth:
        'https://rdmreporting.dev.target.com/rdmreporting/v1/health',
      rdmemailHealth: 'https://rdmemail.dev.target.com/rdmemail/v1/health',
      docSplitHealth: 'https://docsplit-v.dev.target.com/docsplit/v1/health',
      cascadingDBupdate:
        'https://rdmdbupdates.dev.target.com/rdmdbupdates/v1/health',
      serviceNowHealth:
        'https://rdmincident.dev.target.com/rdmincident/v1/health',
      createIncident: 'https://rdmincident.dev.target.com/create/incident',
      getIncident:
        'https://rdmincident.dev.target.com/get/incidents?assignment_group=867040918000960400&per_page=500',
      INDEX: 'index',
      SEARCH: 'search',
      WORKFLOW: 'workflow',
      REALESTATE: 'Real Estate',
      NOBATCH: 'NOBATCH',
      PACAMPAYABLEBUDGET: 'PA CAM Payable Budget',
      PACAMPAYABLERECONCILIATION: 'PA CAM Payable Reconciliation',
      PACAMPAYABLEUNEXPECTED: 'PA CAM Payable Unexpected',
      PACAMREBILLBUDGET: 'PA CAM Rebill Budget',
      PACAMREBILLRECONCILIATION: 'PA CAM Rebill Reconciliation',
      PACAMREBILLUNEXPECTED: 'PA CAM Rebill Unexpected',
      PALEASE: 'PA Lease',
      COMPLETEINDEXINGSTATUS: 'COMPLETE',
    },
    metadata: {
      checkoutAcceptedInputFile: ['application/pdf'],
    },
  },

  stg: {
    auth: {
      host: 'https://oauth.iam.perf.target.com',
      logoutHost: 'https://logonservices.iam.perf.target.com',
      authorizationUrl:
        'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
      clientId: 'rdmplus_npe_im',
      nonce: '1234',
      env: 'stg',
      logoutUrl:
        'https://logonservices.iam.perf.target.com/login/responses/logoff.html',
    },
    apiUrl: {
      fetchFilSize:
        'https://rdmcoreapi-stage.dev.target.com/real_estate_documents/v1/document/filesize?key=7db25859ffd922010569336cf9b5e34ddee7b89f',
      multipleDownloadUrl:
        'https://rdmcoreapi-stage.dev.target.com/real_estate_documents/v1/document/downloads/zip?key=7db25859ffd922010569336cf9b5e34ddee7b89f',
      getFileDownloadUrl: 'https://rdm-stg.dev.target.com/filename?fileName=',
      sendMailUrl: `https://rdmcoreapi-stage.dev.target.com/mail/sendMail?key=7db25859ffd922010569336cf9b5e34ddee7b89f`,
      fileUrl: 'https://rdmapi-stg.dev.target.com/filename',
      searchURL:
        'https://rdmcoreapi-stage.dev.target.com/real_estate_documents/v1/document/search/metadata/',
      search_elastic:
        'https://rdmcoreapi-stage.dev.target.com/real_estate_documents/v1/document/search/es/',
      searchKey: '?key=7db25859ffd922010569336cf9b5e34ddee7b89f',
      indexUrl: 'https://rdmcoreapi-stage.dev.target.com/',
      noBatchUploadUrl:
        'https://rdmcoreapi-stage.dev.target.com/insert/index/metadata',
      updateMetaUrl: 'https://rdmcoreapi-stage.dev.target.com/update/metadata/',
      batchUrl: `https://rdmcoreapi-stage.dev.target.com/update/batch/scan/`,
      getFileUrl: 'https://rdmapi-stg.dev.target.com/filename?fileName=',
      get_file_letter:
        'https://rdmlocation-stage.dev.target.com/get/fileletter/',
      release_batch_url: `https://rdmcoreapi-stage.dev.target.com/release/batch/`,
      key_url: `?key=7db25859ffd922010569336cf9b5e34ddee7b89f`,
      keyUrl: `&key=7db25859ffd922010569336cf9b5e34ddee7b89f`,
      get_batchList_url: `https://rdmcoreapi-stage.dev.target.com/scan/files/`,
      get_batch_metadata: `https://rdmcoreapi-stage.dev.target.com/real_estate_documents/v1/document/metadata?id=`,
      update_checkout_metadata: `https://rdmcoreapi-stage.dev.target.com/update/checkoutmetadata`,
      update_checkin_metadata: `https://rdmcoreapi-stage.dev.target.com/upload/check`,
      cancel_checkout:
        'https://rdmcoreapi-stage.dev.target.com/cancel/checkout',
      ad_group_url: `https://stgapi-internal.target.com/ldap_directory_items/v1/group_members?group=APP-rdmp-publicdocuments-stage&key=7db25859ffd922010569336cf9b5e34ddee7b89f`,
      adGroupFirtsPart:
        'https://stgapi-internal.target.com/ldap_directory_items/v1/group_members?group=',
      adGroupSecondPart: '&key=7db25859ffd922010569336cf9b5e34ddee7b89f',
      legal_adgroup_url: `https://stgapi-internal.target.com/ldap_directory_items/v1/group_members?group=app-rdmp-legalfollowup-re-stage&key=7db25859ffd922010569336cf9b5e34ddee7b89f`,
      business_adgroup_url: `https://stgapi-internal.target.com/ldap_directory_items/v1/group_members?group=app-rdmp-bpfollowup-re-stage&key=7db25859ffd922010569336cf9b5e34ddee7b89f`,
      location_adgroup_url: `https://stgapi-internal.target.com/ldap_directory_items/v1/group_members?group=app-rdmp-loc-re-stage&key=7db25859ffd922010569336cf9b5e34ddee7b89f`,
      change_batch_dialog_content: `Indexing is in progress, if you change the batch then all the tabs will close! Click Change batch to close tabs and go to new batch and Cancel to go back to indexing!`,
      change_batch_first_button: `Change batch`,
      change_batch_second_button: `Cancel`,
      delete_workflow_dialog_content:
        'Are you sure you want to delete this workflow?',
      delete_workflow_first_button: 'Delete',
      delete_workflow_second_button: 'Cancel',
      delete_url: `https://rdmcoreapi-stage.dev.target.com/delete/scan/files?key=7db25859ffd922010569336cf9b5e34ddee7b89f`,
      change_user_first_button: `Change User`,
      change_user_second_button: `Cancel`,
      upload_files_url: `https://rdmcoreapi-stage.dev.target.com/upload?key=7db25859ffd922010569336cf9b5e34ddee7b89f&path=`,
      fetch_complete_batch_metadata: `https://rdmcoreapi-stage.dev.target.com/scan/file/metadata/`,
      bulkEditSearch:
        'https://rdmcoreapi-stage.dev.target.com/bulkedit/search?key=7db25859ffd922010569336cf9b5e34ddee7b89f',
      bulkEditUpdate:
        'https://rdmcoreapi-stage.dev.target.com/bulkedit/update?key=7db25859ffd922010569336cf9b5e34ddee7b89f',
      locationApi: `https://rdmlocation-stage.dev.target.com/get/locationdetails`,
      location_table:
        'https://rdmlocation-stage.dev.target.com/get/listalllocation',
      owned_location:
        'https://rdmlocation-stage.dev.target.com/get/listchecklistbytype/Owned',
      leased_location:
        'https://rdmlocation-stage.dev.target.com/get/listchecklistbytype/Leased',
      add_location: 'https://rdmlocation-stage.dev.target.com/insert/location/',
      update_location:
        'https://rdmlocation-stage.dev.target.com/update/location',
      update_dispositiondate:
        'https://rdmlocation-stage.dev.target.com/update/dispositiondate',
      getlocation:
        'https://rdmlocation-stage.dev.target.com/get/listalllocation/',
      getownedloction:
        'https://rdmlocation-stage.dev.target.com/getchecklistbyid/Owned/',
      update_ownedlocation:
        'https://rdmlocation-stage.dev.target.com/update/checklist/owned',
      getleasedloction:
        'https://rdmlocation-stage.dev.target.com/getchecklistbyid/Leased/',
      update_leasedlocation:
        'https://rdmlocation-stage.dev.target.com/update/checklist/leased',
      getfileletter: 'https://rdmlocation-stage.dev.target.com/get/fileletter/',
      getfileletterbyid:
        'https://rdmlocation-stage.dev.target.com/get/fileletterbyid/',
      addfileletter:
        'https://rdmlocation-stage.dev.target.com/insert/fileletter',
      updatefileletter:
        'https://rdmlocation-stage.dev.target.com/update/fileletter',
      getCheckoutHistory:
        'https://rdmlocation-stage.dev.target.com/get/checkouthistory/byid/',
      checkInOutFileLetter:
        'https://rdmlocation-stage.dev.target.com/checkout/fileletter',
      get_supporting_documents_list:
        'https://rdmcoreapi-stage.dev.target.com/real_estate_documents/v1/document/supportingdocuments',
      delete_supporting_documents_list:
        'https://rdmcoreapi-stage.dev.target.com/delete/supportingdocuments?docId=',
      upload_supporting_documents_list:
        'https://rdmcoreapi-stage.dev.target.com/upload/supportingdocuments?docId=',
      get_workflows: 'https://rdmworkflow-stage.dev.target.com/get/workflows',
      get_workflow: 'https://rdmworkflow-stage.dev.target.com/get/workflow',
      save_workflow: 'https://rdmworkflow-stage.dev.target.com/save/workflow',
      submit_workflow:
        'https://rdmworkflow-stage.dev.target.com/workflow/response',
      delete_workflow:
        'https://rdmworkflow-stage.dev.target.com/delete/workflow',
      workflownotes:
        'https://rdmworkflow-stage.dev.target.com/get/workflownotes',
      addworkflownotes:
        'https://rdmworkflow-stage.dev.target.com/insert/workflownotes',
      getworkflowhistory:
        'https://rdmworkflow-stage.dev.target.com/get/workflowhistory',
      workflow_retrigger:
        'https://rdmworkflow-stage.dev.target.com/workflow/start',
      delete_file:
        'https://rdmcoreapi-stage.dev.target.com/delete/documents?key=7db25859ffd922010569336cf9b5e34ddee7b89f',
      auth_url:
        'https://rdmapi-stg.dev.target.com/real_estate_documents/v1/view/auth/?adgroups=',
      getcamnoticedocs:
        'https://rdmreporting-stg.dev.target.com/get/camnoticedocsbydate?',
      geteasementdocs:
        'https://rdmreporting-stg.dev.target.com/get/leaseeasementdocsbydate?',
      getnoticedocs:
        'https://rdmreporting-stg.dev.target.com/get/leasenoticedocsbydate?',
      getpendingdocs:
        'https://rdmreporting-stg.dev.target.com/get/leasependingdocsbydate?',
      getreviewdocs:
        'https://rdmreporting-stg.dev.target.com/get/leasereviewdocsbydate?',
      getleasequarterlydocs:
        'https://rdmreporting-stg.dev.target.com/get/leasequarterlyinbox?',
      getcamquarterlydocs:
        'https://rdmreporting-stg.dev.target.com/get/camquarterlyinbox?',
      getcampayablebudgetdocs:
        'https://rdmreporting-stg.dev.target.com/get/campayablebudget?',
      getpayablebucketrecords:
        'https://rdmreporting-stg.dev.target.com/get/payablebucketwiserecords?',
      getrebillbucketrecords:
        'https://rdmreporting-stg.dev.target.com/get/rebillbucketwiserecords?',
      getpayablecompletedrecords:
        'https://rdmreporting-stg.dev.target.com/get/completedcampayableworkflow?',
      getrebillcompletedrecords:
        'https://rdmreporting-stg.dev.target.com/get/completedcamrebillworkflow?',
      getreturnedpayableunexpectedrecords:
        'https://rdmreporting-stg.dev.target.com/get/returnedpayableunexpectedrecords?',
      getreturnedrebillrecords:
        'https://rdmreporting-stg.dev.target.com/get/returnedrebillrecords?',
      getreturnedpayableandrecrecords:
        'https://rdmreporting-stg.dev.target.com/get/returnedpayableandrecrecords?',
      rdmapiHealth: 'https://rdmapi-stg.dev.target.com/rdmapi/v1/health',
      datascienceHealth:
        'https://datascienceintg-stg.dev.target.com/datascience/v1/health',
      rdmlocationHealth:
        'https://rdmlocation-stage.dev.target.com/rdmlocation/v1/health',
      rdmocrHealth: 'https://rdmocr-stage.dev.target.com/rdmocr/v1/health',
      ldmtordmsyncHealth:
        'https://ldmtordmsync-stg.dev.target.com/locationsync/v1/health',
      rdmcoreapiHealth:
        'https://rdmcoreapi-stage.dev.target.com/rdmcoreapi/v1/health',
      storageHealth: 'https://rdmcoreapi-stage.dev.target.com/actuator/health',
      rdmworkflowHealth:
        'https://rdmworkflow-stage.dev.target.com/rdmworkflow/v1/health',
      rdmreportingHealth:
        'https://rdmreporting-stg.dev.target.com/rdmreporting/v1/health',
      rdmemailHealth: 'https://rdmemail-stg.dev.target.com/rdmemail/v1/health',
      docSplitHealth:
        'https://docsplit-stage.dev.target.com/docsplit/v1/health',
      cascadingDBupdate:
        'https://rdmdbupdates-stg.dev.target.com/rdmdbupdates/v1/health',

      serviceNowHealth:
        'https://rdmincident-stg.dev.target.com/rdmincident/v1/health',
      createIncident: 'https://rdmincident-stg.dev.target.com/create/incident',
      getIncident:
        'https://rdmincident-stg.dev.target.com/get/incidents?assignment_group=867040918000960400&per_page=500',
      INDEX: 'index',
      SEARCH: 'search',
      WORKFLOW: 'workflow',
      REALESTATE: 'Real Estate',
      NOBATCH: 'NOBATCH',
      PACAMPAYABLEBUDGET: 'PA CAM Payable Budget',
      PACAMPAYABLERECONCILIATION: 'PA CAM Payable Reconciliation',
      PACAMPAYABLEUNEXPECTED: 'PA CAM Payable Unexpected',
      PACAMREBILLBUDGET: 'PA CAM Rebill Budget',
      PACAMREBILLRECONCILIATION: 'PA CAM Rebill Reconciliation',
      PACAMREBILLUNEXPECTED: 'PA CAM Rebill Unexpected',
      PALEASE: 'PA Lease',
      COMPLETEINDEXINGSTATUS: 'COMPLETE',
    },
    metadata: {
      checkoutAcceptedInputFile: ['application/pdf'],
    },
  },

  prod: {
    auth: {
      host: 'https://oauth.iam.target.com',
      logoutHost: 'https://logonservices.iam.target.com',
      authorizationUrl: 'https://oauth.iam.target.com/auth/oauth/v2/authorize',
      clientId: 'rdmplus_prod_im',
      nonce: '1234',
      env: 'prod',
      logoutUrl:
        'https://logonservices.iam.target.com/login/responses/logoff.html',
    },
    apiUrl: {
      fetchFilSize:
        'https://rdmplus.target.com/real_estate_documents/v1/document/filesize?key=b29e7441e2b154dbcccfe325df4ba60f18fcfb70',
      multipleDownloadUrl:
        'https://rdmplus.target.com/real_estate_documents/v1/document/downloads/zip?key=b29e7441e2b154dbcccfe325df4ba60f18fcfb70',
      getFileDownloadUrl: 'https://rdm.prod.target.com/filename?fileName=',
      sendMailUrl: `https://rdmcoreapi.prod.target.com/mail/sendMail?key=b29e7441e2b154dbcccfe325df4ba60f18fcfb70`,
      fileUrl: 'https://rdmapi.prod.target.com/filename',
      fileUrlExternal: 'https://rdmplus.target.com/filename',
      getFileUrlExternal:
        'https://rdmplus.target.com/real_estate_documents/v1/view/file?fileName=',
      searchURL:
        'https://rdmplus.target.com/real_estate_documents/v1/document/search/metadata/',
      search_elastic:
        'https://rdmplus.target.com/real_estate_documents/v1/document/search/es/',
      searchKey: '?key=b29e7441e2b154dbcccfe325df4ba60f18fcfb70',
      indexUrl: 'https://rdmcoreapi.prod.target.com/',
      noBatchUploadUrl:
        'https://rdmcoreapi.prod.target.com/insert/index/metadata',
      updateMetaUrl: 'https://rdmcoreapi.prod.target.com/update/metadata/',
      batchUrl: `https://rdmcoreapi.prod.target.com/update/batch/scan/`,
      getFileUrl: 'https://rdmapi.prod.target.com/filename?fileName=',
      get_file_letter: 'https://rdmlocation.prod.target.com/get/fileletter/',
      release_batch_url: `https://rdmcoreapi.prod.target.com/release/batch/`,
      key_url: `?key=b29e7441e2b154dbcccfe325df4ba60f18fcfb70`,
      keyUrl: `&key=b29e7441e2b154dbcccfe325df4ba60f18fcfb70`,
      get_batchList_url: `https://rdmcoreapi.prod.target.com/scan/files/`,
      get_batch_metadata: `https://rdmplus.target.com/real_estate_documents/v1/document/metadata?id=`,
      update_checkout_metadata: `https://rdmcoreapi.prod.target.com/update/checkoutmetadata`,
      update_checkin_metadata: `https://rdmcoreapi.prod.target.com/upload/check`,
      cancel_checkout: 'https://rdmcoreapi.prod.target.com/cancel/checkout',
      ad_group_url: `https://api-internal.target.com/ldap_directory_items/v1/group_members?group=APP-rdmp-publicdocuments-prod&key=b29e7441e2b154dbcccfe325df4ba60f18fcfb70`,
      adGroupFirtsPart:
        'https://api-internal.target.com/ldap_directory_items/v1/group_members?group=',
      adGroupSecondPart: '&key=b29e7441e2b154dbcccfe325df4ba60f18fcfb70',
      legal_adgroup_url: `https://api-internal.target.com/ldap_directory_items/v1/group_members?group=app-rdmp-legalfollowup-re-prod&key=b29e7441e2b154dbcccfe325df4ba60f18fcfb70`,
      business_adgroup_url: `https://api-internal.target.com/ldap_directory_items/v1/group_members?group=app-rdmp-bpfollowup-re-prod&key=b29e7441e2b154dbcccfe325df4ba60f18fcfb70`,
      location_adgroup_url: `https://api-internal.target.com/ldap_directory_items/v1/group_members?group=app-rdmp-loc-re-prod&key=b29e7441e2b154dbcccfe325df4ba60f18fcfb70`,
      change_batch_dialog_content: `Indexing is in progress, if you change the batch then all the tabs will close! Click Change batch to close tabs and go to new batch and Cancel to go back to indexing!`,
      change_batch_first_button: `Change batch`,
      change_batch_second_button: `Cancel`,
      delete_workflow_dialog_content:
        'Are you sure you want to delete this workflow?',
      delete_workflow_first_button: 'Delete',
      delete_workflow_second_button: 'Cancel',
      delete_url: `https://rdmcoreapi.prod.target.com/delete/scan/files?key=b29e7441e2b154dbcccfe325df4ba60f18fcfb70`,
      change_user_first_button: `Change User`,
      change_user_second_button: `Cancel`,
      upload_files_url: `https://rdmcoreapi.prod.target.com/upload?key=b29e7441e2b154dbcccfe325df4ba60f18fcfb70&path=`,
      fetch_complete_batch_metadata: `https://rdmcoreapi.prod.target.com/scan/file/metadata/`,
      bulkEditSearch:
        'https://rdmcoreapi.prod.target.com/bulkedit/search?key=b29e7441e2b154dbcccfe325df4ba60f18fcfb70',
      bulkEditUpdate:
        'https://rdmcoreapi.prod.target.com/bulkedit/update?key=b29e7441e2b154dbcccfe325df4ba60f18fcfb70',
      locationApi: `https://rdmlocation.prod.target.com/get/locationdetails`,
      location_table: 'https://rdmlocation.prod.target.com/get/listalllocation',
      owned_location:
        'https://rdmlocation.prod.target.com/get/listchecklistbytype/Owned',
      leased_location:
        'https://rdmlocation.prod.target.com/get/listchecklistbytype/Leased',
      add_location: 'https://rdmlocation.prod.target.com/insert/location/',
      update_location: 'https://rdmlocation.prod.target.com/update/location',
      update_dispositiondate:
        'https://rdmlocation.prod.target.com/update/dispositiondate',
      getlocation: 'https://rdmlocation.prod.target.com/get/listalllocation/',
      getownedloction:
        'https://rdmlocation.prod.target.com/getchecklistbyid/Owned/',
      update_ownedlocation:
        'https://rdmlocation.prod.target.com/update/checklist/owned',
      getleasedloction:
        'https://rdmlocation.prod.target.com/getchecklistbyid/Leased/',
      update_leasedlocation:
        'https://rdmlocation.prod.target.com/update/checklist/leased',
      get_supporting_documents_list:
        'https://rdmplus.target.com/real_estate_documents/v1/document/supportingdocuments',
      delete_supporting_documents_list:
        'https://rdmcoreapi.prod.target.com/delete/supportingdocuments?docId=',
      upload_supporting_documents_list:
        'https://rdmcoreapi.prod.target.com/upload/supportingdocuments?docId=',
      get_workflows: 'https://rdmworkflow.prod.target.com/get/workflows',
      get_workflow: 'https://rdmworkflow.prod.target.com/get/workflow',
      save_workflow: 'https://rdmworkflow.prod.target.com/save/workflow',
      submit_workflow: 'https://rdmworkflow.prod.target.com/workflow/response',
      delete_workflow: 'https://rdmworkflow.prod.target.com/delete/workflow',
      workflownotes: 'https://rdmworkflow.prod.target.com/get/workflownotes',
      addworkflownotes:
        'https://rdmworkflow.prod.target.com/insert/workflownotes',
      getworkflowhistory:
        'https://rdmworkflow.prod.target.com/get/workflowhistory',
      workflow_retrigger: 'https://rdmworkflow.prod.target.com/workflow/start',
      delete_file:
        'https://rdmcoreapi.prod.target.com/delete/documents?key=b29e7441e2b154dbcccfe325df4ba60f18fcfb70',
      auth_url:
        'https://rdmplus.target.com/real_estate_documents/v1/view/auth/?adgroups=',
      getcamnoticedocs:
        'https://rdmreporting.prod.target.com/get/camnoticedocsbydate?',
      geteasementdocs:
        'https://rdmreporting.prod.target.com/get/leaseeasementdocsbydate?',
      getnoticedocs:
        'https://rdmreporting.prod.target.com/get/leasenoticedocsbydate?',
      getpendingdocs:
        'https://rdmreporting.prod.target.com/get/leasependingdocsbydate?',
      getreviewdocs:
        'https://rdmreporting.prod.target.com/get/leasereviewdocsbydate?',
      getleasequarterlydocs:
        'https://rdmreporting.prod.target.com/get/leasequarterlyinbox?',
      getcamquarterlydocs:
        'https://rdmreporting.prod.target.com/get/camquarterlyinbox?',
      getcampayablebudgetdocs:
        'https://rdmreporting.prod.target.com/get/campayablebudget?',
      getpayablebucketrecords:
        'https://rdmreporting.prod.target.com/get/payablebucketwiserecords?',
      getrebillbucketrecords:
        'https://rdmreporting.prod.target.com/get/rebillbucketwiserecords?',
      getpayablecompletedrecords:
        'https://rdmreporting.prod.target.com/get/completedcampayableworkflow?',
      getrebillcompletedrecords:
        'https://rdmreporting.prod.target.com/get/completedcamrebillworkflow?',
      getreturnedpayableunexpectedrecords:
        'https://rdmreporting.prod.target.com/get/returnedpayableunexpectedrecords?',
      getreturnedrebillrecords:
        'https://rdmreporting.prod.target.com/get/returnedrebillrecords?',
      getreturnedpayableandrecrecords:
        'https://rdmreporting.prod.target.com/get/returnedpayableandrecrecords?',
      getfileletter: 'https://rdmlocation.prod.target.com/get/fileletter/',
      getfileletterbyid:
        'https://rdmlocation.prod.target.com/get/fileletterbyid/',
      addfileletter: 'https://rdmlocation.prod.target.com/insert/fileletter',
      updatefileletter: 'https://rdmlocation.prod.target.com/update/fileletter',
      getCheckoutHistory:
        'https://rdmlocation.prod.target.com/get/checkouthistory/byid/',
      checkInOutFileLetter:
        'https://rdmlocation.prod.target.com/checkout/fileletter',
      rdmapiHealth: 'https://rdmapi.prod.target.com/rdmapi/v1/health',
      datascienceHealth:
        'https://datascienceintg.prod.target.com/datascience/v1/health',
      rdmlocationHealth:
        'https://rdmlocation.prod.target.com/rdmlocation/v1/health',
      rdmocrHealth: 'https://rdmocr.prod.target.com/rdmocr/v1/health',
      ldmtordmsyncHealth:
        'https://ldmtordmsync.prod.target.com/locationsync/v1/health',
      rdmcoreapiHealth:
        'https://rdmcoreapi.prod.target.com/rdmcoreapi/v1/health',
      storageHealth: 'https://rdmcoreapi.prod.target.com/actuator/health',
      rdmworkflowHealth:
        'https://rdmworkflow.prod.target.com/rdmworkflow/v1/health',
      rdmreportingHealth:
        'https://rdmreporting.prod.target.com/rdmreporting/v1/health',
      rdmemailHealth: 'https://rdmemail.prod.target.com/rdmemail/v1/health',
      docSplitHealth: 'https://docsplit.prod.target.com/docsplit/v1/health',
      cascadingDBupdate:
        'https://rdmdbupdates.prod.target.com/rdmdbupdates/v1/health',

      serviceNowHealth:
        'https://rdmincident.prod.target.com/rdmincident/v1/health',
      createIncident: 'https://rdmincident.prod.target.com/create/incident',
      getIncident:
        'https://rdmincident.prod.target.com/get/incidents?assignment_group=867040918000960400&per_page=500',
      INDEX: 'index',
      SEARCH: 'search',
      WORKFLOW: 'workflow',
      REALESTATE: 'Real Estate',
      NOBATCH: 'NOBATCH',
      PACAMPAYABLEBUDGET: 'PA CAM Payable Budget',
      PACAMPAYABLERECONCILIATION: 'PA CAM Payable Reconciliation',
      PACAMPAYABLEUNEXPECTED: 'PA CAM Payable Unexpected',
      PACAMREBILLBUDGET: 'PA CAM Rebill Budget',
      PACAMREBILLRECONCILIATION: 'PA CAM Rebill Reconciliation',
      PACAMREBILLUNEXPECTED: 'PA CAM Rebill Unexpected',
      PALEASE: 'PA Lease',
      COMPLETEINDEXINGSTATUS: 'COMPLETE',
    },
    metadata: {
      checkoutAcceptedInputFile: ['application/pdf'],
    },
  },
}
//   stg: {
//     auth: {
//       host: 'https://oauth.iam.perf.target.com',
//       logoutHost: 'https://logonservices.iam.perf.target.com',
//       clientId: '',
//       nonce: '1234',
//     },
//     apiUrl: {
//       tracker: 'https://p2ptrackerapi-stg.dev.target.com',
//       balancing: 'https://p2ptrackerapi-stg.dev.target.com/tools',
//     },
//   },
//   prod: {
//     auth: {
//       host: 'https://oauth.iam.target.com',
//       logoutHost: 'https://logonservices.iam.target.com',
//       clientId: '',
//       nonce: '1234',
//     },
//     apiUrl: {
//       tracker: 'https://p2ptrackerapi.prod.target.com',
//       balancing: 'https://p2ptrackerapi.prod.target.com/tools',
//     },
//   },
// }

// env.js sets APP_ENV
const appEnv = process.env.REACT_APP_ENV
const config = appEnv != null ? envConfigs[appEnv] : {}
// commonConfig and the config for the matching REACT_APP_ENV are combined.
// Values in the environment-specific config will overwrite commonConfig keys
// if they share the same name.
const apiConfig = merge(commonConfig, config)
export default apiConfig
// The following line is purely for demonstration purposes and can be removed.
if (process.env.NODE_ENV !== 'production') {
  console.log(`${appEnv} ENV apiConfig:`, apiConfig)
}
