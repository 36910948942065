import React from 'react'
import SideNavBar from './components/navbar/SideNavBar'
import { MuiThemeProvider } from '@material-ui/core/styles'
import muiTheme from './themeConfig'
import CssBaseline from '@material-ui/core/CssBaseline'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
// import {useHistory} from 'react-router'
import Search from './components/search/Search'
// import IndexView from './components/index/IndexView'
import { IndexView } from './components/index/IndexView'
import BulkEdit from './components/bulkEdit/bulkEdit'
import { ErrorPage } from './components/common/error'
import Upload from './components/upload/Upload'
import { AuthProvider } from '@praxis/component-auth'
// import { properties } from './Properties'
import apiConfig from './config/apiConfig'
import { Workflow } from './components/workflow/workflow'
import Locationhomepage from './components/location/locationhomepage'
import { ToastProvider } from 'react-toast-notifications'
import { DefaultToast } from 'react-toast-notifications'
import Reporting from './components/reporting/reporting'
//File URL authentication code
import FileViewerComponent from './components/file/fileViewerComponent'
// import 'bootstrap/dist/css/bootstrap.min.css'
import './style.css'
import { HealthCheck } from './components/healthboard/healthCheckNew'
// import Support from './components/support/supporthomepage'

const NavRoute = ({ path, component, ...props }) => {
  const RenderComponent = component
  return (
    <Route
      path={path}
      component={() => (
        <SideNavBar>
          <RenderComponent {...props} />
        </SideNavBar>
      )}
    />
  )
}

export const MyCustomToast = ({ children, ...props }) => (
  <DefaultToast {...props} style={{ backgroundColor: '#f8f9fa' }}>
    <div>{children}</div>
  </DefaultToast>
)

const App = () => {
  for (var key in window.localStorage) {
    if (key.includes(`docMetadata`)) {
      window.localStorage.removeItem(key)
      console.log(`deleting ${key}`)
    }
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={muiTheme}>
        <div className="App">
          <ToastProvider
            placement="top-center"
            components={{ Toast: MyCustomToast }}
          >
            <AuthProvider
              authorizationUrl={apiConfig.auth.authorizationUrl}
              clientId={apiConfig.auth.clientId}
              loginRedirect={`${window.location.origin}`}
              // logoutRedirect={`${window.location.origin}/`}
              logoutUrl={apiConfig.auth.logoutUrl}
              onLogout={() => {
                localStorage.clear()
                window.location = `${window.location.origin}/`
              }}
            >
              <Router>
                <Switch>
                  <NavRoute exact path="/" component={Search} />
                  {/* <NavRoute exact path="/newSearch" component={SearchSection} /> */}
                  <NavRoute path="/index" component={IndexView} />
                  <NavRoute path="/upload" component={Upload} />
                  <NavRoute path="/workflow" component={Workflow} />
                  <NavRoute path="/location" component={Locationhomepage} />
                  <NavRoute path="/reporting" component={Reporting} />
                  <NavRoute path="/bulkEdit" component={BulkEdit} />
                  <NavRoute path="/filename" component={FileViewerComponent} />
                  <NavRoute
                    path="/real_estate_documents/v1/view/file"
                    component={FileViewerComponent}
                  />
                  {/* <NavRoute path="/health" component={HealthCheck} /> */}
                  <NavRoute path="/support" component={HealthCheck} />
                  <Route path="/unauthorised" component={ErrorPage} />
                </Switch>
              </Router>
            </AuthProvider>
          </ToastProvider>
        </div>
      </MuiThemeProvider>
    </React.Fragment>
  )
}

export default App
